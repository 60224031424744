<template>
  <!-- 账户管理-- 表格区域 -->
  <div class="lists">
    <!-- Element ui -- Form表单 -->
    <template>
      <el-table v-loading="loading" :data="tableData" style="width: 100%" :header-cell-style="{ background: '#4888FF' }"
        :max-height="tableHeight" :row-class-name="tableRowClassName">
        <!-- 索引序号 -->
        <el-table-column type="index" width="80" fixed label="序号"></el-table-column>
        <!-- 主体 -->
        <el-table-column prop="gname" label="主体" width="260">
        </el-table-column>
        <!-- 账户编号 -->
        <el-table-column prop="id" label="账户编号"  width="80">
        </el-table-column>
        <!-- 用户名 -->
        <el-table-column prop="cuser" label="用户名">
        </el-table-column>
        <!-- 用户类型 -->
        <el-table-column prop="type" label="账号类型">
          <temalate slot-scope="{row}">{{ account[row.type] }}</temalate>
        </el-table-column>
        <!-- 登入账号 -->
        <!-- <el-table-column prop="loginAccount" label="登入账号">
        </el-table-column> -->
        <!-- 手机号码 -->
        <el-table-column prop="phone" label="手机号码">
        </el-table-column>
        <!-- 邮箱 -->
        <el-table-column prop="email" label="邮箱" width="210">
        </el-table-column>
        <!-- 创建时间 -->
        <el-table-column prop="ctime" label="创建时间" width="180">
        </el-table-column>
        <!-- 操作 -->
        <!-- <el-table-column label="详情" fixed="right" width="180">
          <template slot-scope="scope">
            <el-link type="primary" style="margin-right:14px;" @click="isDisplay.isShow=true">编辑</el-link>
            <el-link type="primary" @click="delAccount(scope.row)">删除</el-link>
          </template>
        </el-table-column> -->
      </el-table>
    </template>
    <!-- Element ui--Pagination分页 -->
    <!-- <template>
      <el-pagination background @current-change="handleCurrentChange" @size-change="handleSizeChange"
        :current-page="params.page" :page-size="params.size" layout="total, prev, pager, next,sizes,jumper"
        :total="total">
      </el-pagination>
    </template> -->
  </div>
</template>

<script>
import { accountManagementList } from '../../../utils/api'
export default {
  props: ['isDisplay', 'params'],
  data() {
    return {
      loading: true,
      total: 0,
      tableData: [],
      account: ['总管理员', '子管理员', '分管理员', '其他'],
      tableHeight: 0,
    };
  },
  created() {
    this.tableHeight = document.documentElement.clientHeight - 300
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.tableHeight = document.documentElement.clientHeight - 300;
    })
  },
  watch: {
    params: {
      handler(newVal) {
        this.getTableData()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async getTableData() {
      this.loading = true
      this.tableData = []
      const res = await accountManagementList(this.params)
      res.data.data.forEach(item => {
        this.tableData = this.tableData.concat(item.user.map(e => ({ ...e, gname: item.gname })))
      })
      this.total = this.tableData.length
      this.loading = false
    },
    //隔行变色
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      } else if (rowIndex % 2 === 1) {
        return "success-row";
      }
      return "";
    },
    // 分页的方法
    // handleSizeChange(val) {
    //   console.log(`每页 ${val} 条`);
    // },
    // handleCurrentChange(val) {
    //   console.log(`当前页: ${val}`);
    // },
    // 删除
    // delAccount(ids) {
    //   // 通过对应的id删除账户
    //   this.$confirm('此操作将永久删除该文件, 是否继续?', '账户删除', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     this.$message({
    //       type: 'success',
    //       message: '删除成功!'
    //       // 删除账户信息

    //     });
    //   }).catch(() => {
    //     this.$message({
    //       type: 'info',
    //       message: '已取消删除'
    //     });
    //   });
    // },
  },
};
</script>

<style scoped>
.el-table /deep/ .warning-row {
  background: #fff;
}

.el-table /deep/ .success-row {
  background: #f1f3f8;
}

.el-table /deep/ thead {
  font-family: "SHSCN_Regular";
  color: #fff;
  font-weight: normal;
}

.el-table /deep/ .cell {
  font-family: "SHSCN_Regular";
  font-size: 14px;
  font-weight: normal;
}

.lists .el-pagination {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Source Han Sans CN-Regular";
}

.lists /deep/ .el-pagination__jump {
  margin-left: 0;
}

.el-table /deep/ .el-table__cell {
  text-align: center;
  padding: 16px 0;
}
</style>