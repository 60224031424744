<template>
  <!-- 新增账户 -->
  <div class="addAccount">
    <el-dialog title="新增账户" :visible.sync="isDisplay.isShow" :close-on-click-modal="false">
      <!-- element ui --验证表单 -->
      <!-- 信息录入 -->
      <div class="informInput">
        <h3>信息录入</h3>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <!--登录账号-->
          <el-form-item label="登录账号" prop="loginAccount">
            <el-input v-model="ruleForm.loginAccount"></el-input>
          </el-form-item>
          <!--登录密码-->
          <el-form-item label="登录密码" prop="loginPassword">
            <el-input v-model="ruleForm.loginPassword"></el-input>
          </el-form-item>
          <!--用户名-->
          <el-form-item label="用户名" prop="userName">
            <el-input v-model="ruleForm.userName"></el-input>
          </el-form-item>
          <!--手机号码-->
          <el-form-item label="手机号码" prop="phone">
            <el-input v-model="ruleForm.phone"></el-input>
          </el-form-item>
          <!--邮箱-->
          <el-form-item label="邮箱">
            <el-input v-model="ruleForm.email"></el-input>
          </el-form-item>
          <!-- 用户类型 -->
          <el-form-item label="用户类型" prop="userValue">
            <el-select v-model="ruleForm.userValue" placeholder="请选择用户类型">
              <el-option :label="item.label" :value="item.value" v-for="item in userType" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <!-- 权限配置 -->
      <div class="permissionsAllocation">
        <h3>权限配置</h3>
        <el-tree :data="permissionsArr" show-checkbox node-key="id"
        :default-expanded-keys="[1,2,3,4,5]"
        :default-checked-keys="[9,10,11,13]"
        >
        <!-- :props="defaultProps" -->
      </el-tree>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelForm">取 消</el-button>
        <el-button type="primary" @click="isDisplay.isShow = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 引入vuex的辅助函数
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
export default {
  props:['isDisplay'],
  data() {
    return {
      // defaultProps: {
      //     children: 'children',
      //     label: 'label'
      //   }
    };
  },
  computed:{
    ...mapState('accountManage',['ruleForm','userType','rules','permissionsArr'])
  },
  mounted() {
    
  },

  methods: {
    // 取消提交表达那
    cancelForm(){
      for(let i in this.ruleForm){
        this.ruleForm[i] = ''
      }
    }
  },
};
</script>

<style scoped>
/* 设置全局字体 */
.addAccount{
  font-family: 'SHSCN_Regular';
}
/* 弹窗的title */
.addAccount /deep/ .el-dialog__title{
  font-family: 'SHSCN_Bold';
}
/* 信息录入--开始 */
/* 标题 */
.informInput{
  margin: 1vh 0 0 1.36vw;
}
.informInput h3{
  font-family: 'SHSCN_Bold';
}
.informInput /deep/ .el-form{
  display: grid;
  grid-template-columns: 33% 33% 33%;
  margin: 2.78vh 0 0 -1vw;
}
/* 信息录入--结束 */

/* 权限配置--开始 */
.permissionsAllocation{
  margin: 1vh 0 0 1.36vw;
}
.permissionsAllocation h3{
  font-family: 'SHSCN_Bold';
}
.permissionsAllocation /deep/ .el-tree{
  margin: 2.78vh 0 0 -1vw;
  display: flex;
  justify-content: space-evenly;
}
/* 权限配置--结束 */
</style>